<!--
 * @Descripttion:
 * @version:
 * @Author: 魏鑫
 * @Date: 2020-01-13 10:54:45
 * @LastEditors  : 李波
 * @LastEditTime : 2020-01-21 14:00:40
 -->
<template>
  <div class="content">
    <div class="title">
      <div class="t_date">
        <ul>
          <li @click="selectDate('1')" :class="select=='1'?'select':''"><span>年</span></li>
          <li @click="selectDate('2')" :class="select=='2'?'select':''"><span>月</span></li>
          <li @click="selectDate('3')" :class="select=='3'?'select':''"><span>日</span></li>
          <li @click="selectDate('4')" :class="select=='4'?'select':''"><span>区间</span></li>
        </ul>
      </div>
      <div class="b_date">
        <div>
          <span>{{ select==='4'?'起始日期':'日期' }}</span>
        </div>
        <div @click="showChangeDate">
          <i class="iconfont iconkaoqintongji" />
          <span>{{showyearmon}}</span>
          <md-icon name="arrow-down" size="md"></md-icon>
        </div>
      </div>
      <div class="b_date" v-if="select==='4'">
        <div>
          <span>结束日期</span>
        </div>
        <div @click="showendDate">
          <i class="iconfont iconkaoqintongji" />
          <span>{{endshowyear}}</span>
          <md-icon name="arrow-down" size="md"></md-icon>
        </div>
      </div>
    </div>
    <md-date-picker
      ref="datePicker"
      v-model="isShowData1"
      type="custom"
      title="选择日期"
      is-twelve-hours
      :custom-types="['yyyy']"
      :default-date="currentDate"
      @confirm="onDatePickerConfirm"
    ></md-date-picker>
    <md-date-picker
      ref="datePicker"
      v-model="isShowData2"
      type="custom"
      title="选择日期"
      is-twelve-hours
      :custom-types="['yyyy', 'MM']"
      :default-date="currentDate"
      @confirm="onDatePickerConfirm"
    ></md-date-picker>
    <md-date-picker
      ref="datePicker"
      v-model="isShowData3"
      type="custom"
      title="选择日期"
      is-twelve-hours
      :custom-types="['yyyy', 'MM','dd']"
      :default-date="currentDate"
      @confirm="onDatePickerConfirm"
    ></md-date-picker>
    <md-date-picker
      ref="datePicker"
      v-model="isShowData4"
      type="custom"
      title="选择日期"
      is-twelve-hours
      :custom-types="['yyyy', 'MM','dd']"
      :default-date="currentDate"
      @confirm="endPickerConfirm"
    ></md-date-picker>
      <div class="datum">
          <div class="datum_data">
            <ul>
                <li>
                  <div>
                    <h3>{{ tive.tivespolicyno }}</h3>
                    <p>生效件数</p>
                  </div>
                </li>
                <li>
                  <div class="li_style">
                   <h3>{{ tive.tivejbfmount }}</h3>
                    <p>生效标保</p>
                  </div>
                </li>
                <li>
                  <div >
                    <h3>{{ tive.tivemount }}</h3>
                    <p>生效规保</p>
                  </div>
                </li>
            </ul>
            <ul>
                <li>
                  <div>
                   <h3>{{ tive.tiveempnum }}</h3>
                    <p>出单人力</p>
                  </div>
                </li>
                <li >
                  <div class="li_style">
                   <h3>{{ tive.tiveempmount }}</h3>
                    <p>人均保费</p>
                  </div>
                </li>
                <li>
                  <div>
                    <h3>{{ tive.tivespolicynomount }}</h3>
                    <p>件均保费</p>
                  </div>
                </li>
            </ul>
            <ul>
                <li>
                  <div>
                    <h3>{{ tive.tiveyearnumsum }}</h3>
                    <p>期缴件数</p>
                  </div>
                </li>
                <li >
                  <div class="li_style">
                     <h3>{{ tive.tiveyearnummount }}</h3>
                    <p>期缴保费</p>
                  </div>
                </li>
                <li>
                  <div>
                   <h3>{{ tive.tiveyearnumproportion }}</h3>
                    <p>期缴占比</p>
                  </div>
                </li>
            </ul>
          </div>
      </div>
    <!-- </div> -->
<div class="chart">
      <div class="c_title">
        <span class="span">机构占比</span>
      </div>
       <ul>
         <li @click="selecttjcode('1')" :class="isgb=='1'?'select':''">规保</li>
         <li @click="selecttjcode('2')" :class="isgb=='2'?'select':''">标保</li>
        </ul>
        <!-- legend-position="right" -->
      <ve-ring :data="chartData" width="356.25px"  :settings="chartSettings" height="400px"   :extend="tjextend" class="imgstyle"></ve-ring>
    </div>
    <div class="chart">
      <div class="c_title">
        <span class="span">机构排名</span>
      </div>
       <ul>
         <li @click="selectranking('1')" :class="isranking=='1'?'select':''">规保</li>
         <li @click="selectranking('2')" :class="isranking=='2'?'select':''">标保</li>
        </ul>
      <ve-bar :data="chartDatas" width="356.25px"  :settings="chartSettings" height="400px" :extend="extend" class="imgstyle"></ve-bar>
    </div>
  </div>
</template>

<script>
import { RadioBox, RadioGroup, Toast } from 'mand-mobile'
import { reptive } from '@/api/smarkboard/effectivereport/index'
export default {
  data () {
    this.chartSettings = {
      labelMap: {
        advancemount: '规保保费',
        proportionmount: '规保占比',
        advancejbfmount: '标保保费',
        proportionJbfmount: '标保占比',
        tjcode: '机构号',
        tjcodename: '机构名称'
      }
    }
    this.tjextend = {
      legend: {
        type: 'scroll',
        orient: 'vertical',
        right: 0,
        top: 10,
        bottom: 20
      }
    }
    this.extend = {
      'xAxis.0.axisLabel.rotate': 25
      // 'yAxis.0.axisLabel.rotate': 60
    }
    return {
      isShowData: false,
      isShowData1: false,
      isShowData2: false,
      isShowData3: false,
      isShowData4: false,
      select: '1',
      isgb: '1',
      isranking: '1',
      yearmon: '',
      showyearmon: '',
      endyear: '',
      endshowyear: '',
      starTime: '',
      endTime: '',
      year: '',
      month: '',
      day: '',
      times: '',
      advanceLast: [],
      tive: {},
      chartData: {
        columns: [ 'tjcodename', 'advancemount', 'proportionmount' ],
        rows: []
      },
      chartDatas: {
        columns: [ 'tjcodename', 'advancemount' ],
        rows: []
      },
      current: 1,
      items: [
        { name: 1, label: '年' },
        { name: 2, label: '月' },
        { name: 3, label: '日' },
        { name: 4, label: '区间' }
      ],
      qhzb: 'gb',
      qhpm: 'gb',
      itemqh: [
        { name: 'gb', label: '规保' },
        { name: 'bb', label: '标保' }

      ],
      currentDate: new Date(),
      isDatePickerShow: false,
      datePickerValue: '',
      tiveBB: [],
      tiveGB: [],
      // 规保
      tiveQjBB: [],
      tiveQjGB: []
    }
  },
  created () {
    var d = new Date()
    var year = d.getFullYear()
    this.yearmon = `${year}`
    this.showyearmon = `${year}年`
    this.getData()
  },
  methods: {
    // 获取视图数据
    async getData (isInit = true) {
      let data = {}
      let date = this.yearmon.split('-')
      if (this.select === '1') {
        data = {
          year: this.yearmon
        }
      } else if (this.select === '2') {
        data = {
          year: date[0],
          month: date[1]
        }
      } else if (this.select === '3') {
        data = {
          year: date[0],
          month: date[1],
          day: date[2]
        }
      } else {
        data = { starTime: this.yearmon, endTime: this.endyear }
      }
      let res = await reptive(data)
      this.tive = res.data.data.tive
      this.tiveBB = res.data.data.tiveBB
      this.tiveGB = res.data.data.tiveGB
      this.tiveQjBB = res.data.data.tiveQJBB
      this.tiveQjGB = res.data.data.tiveQJBB
      this.chartData.rows = this.tiveGB
      this.chartDatas.rows = this.tiveQjGB
    },
    selectDate (val) {
      this.select = val
      var d = new Date()
      var year = d.getFullYear()
      var month = (d.getMonth() + 1)
      month > 9 ? month : month = '0' + month
      var day = d.getDate()
      day > 9 ? day : day = '0' + day
      if (val == '1') {
        this.yearmon = `${year}`
        this.showyearmon = `${year}年`
      } else if (val == '2') {
        this.yearmon = `${year}-${month}`
        this.showyearmon = `${year}年${month}月`
      } else {
        this.yearmon = `${year}-${month}-${day}`
        this.showyearmon = `${year}年${month}月${day}日`
        this.endyear = `${year}-${month}-${day}`
        this.endshowyear = `${year}年${month}月${day}日`
      }
      this.getData()
    },
    showChangeDate () {
      if (this.select == '1') {
        this.isShowData1 = true
      } else if (this.select == '2') {
        this.isShowData2 = true
      } else {
        this.isShowData3 = true
      }
    },
    showendDate () {
      this.isShowData4 = true
    },
    // 机构占比规保标保切换
    selecttjcode (val) {
      this.isgb = val
      if (val === '1') {
        this.chartData.columns = [ 'tjcodename', 'advancemount', 'proportionmount' ]
        this.chartData.rows = this.tiveGB
      }
      if (val === '2') {
        this.chartData.columns = [ 'tjcodename', 'advancejbfmount', 'proportionJbfmount' ]
        this.chartData.rows = this.tiveBB
      }
    },
    // 机构排名是规保还是标保的
    selectranking (val) {
      this.isranking = val
      if (val === '1') {
        this.chartDatas.columns = [ 'tjcodename', 'advancemount' ]
        this.chartDatas.rows = this.tiveQjGB
      }
      if (val === '2') {
        this.chartDatas.columns = [ 'tjcodename', 'advancejbfmount' ]
        this.chartDatas.rows = this.tiveQjBB
      }
    },
    onDatePickerConfirm (columnsValue) {
      let aaa = []
      let bbb = []
      for (var i = 0; i < columnsValue.length; i++) {
        columnsValue[i].value > 9 ? columnsValue[i].value : columnsValue[i].value = '0' + columnsValue[i].value
        aaa.push(columnsValue[i].text)
        bbb.push(columnsValue[i].value)
      }
      if (bbb.length === 1) {
        this.endshowyear = aaa.join('')
      } else if (bbb.length === 2) {
        this.showyearmon = aaa[0] + bbb[1] + '月'
      } else {
        this.showyearmon = aaa[0] + bbb[1] + '月' + bbb[1] + '日'
      }
      // this.showyearmon = aaa.join('')
      this.yearmon = bbb.join('-')
      this.getData()
    },
    endPickerConfirm (columnsValue) {
      columnsValue[i].value > 9 ? columnsValue[i].value : columnsValue[i].value = '0' + columnsValue[i].value
      let aaa = []
      let bbb = []
      for (var i = 0; i < columnsValue.length; i++) {
        aaa.push(columnsValue[i].text)
        bbb.push(columnsValue[i].value)
      }
      if (bbb.length === 1) {
        this.endshowyear = aaa.join('')
      } else if (bbb.length === 2) {
        this.endshowyear = aaa[0] + bbb[1] + '月'
      } else {
        this.endshowyear = aaa[0] + bbb[1] + '月' + bbb[1] + '日'
      }
      // this.endshowyear = aaa.join('')
      this.endyear = bbb.join('-')
      this.getData()
    }
  }
}
</script>

<style lang="stylus" scoped>
.content {
  height 100%;
  overflow-y: hidden;
  background-color #fff
  .title {
    border-bottom 30px solid #F5F6F8
    .t_date {
      border-bottom 1px solid #EEEEEE
      ul {
        width: 80%;
        overflow: hidden;
        margin: 60px auto;
        text-align center

        li {
          width: 24%;
          display: block;
          float: left;
          border 1px solid #EEEEEE
          padding-top 10px
          padding-bottom 10px
          span {
            font-size:35px;
            font-family:PingFang SC;
            font-weight:500;
            color:rgba(56,56,56,1);
          }
        }
        .select {
          border 1px solid #228ACE
          background-color #E6F2FC
        }
      }
    }
    .b_date {
      display flex
      justify-content space-between
      padding 30px 70px
      span {
        font-size:40px;
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(142,142,144,1);
      }
    }
  }
}
.datum_data {
    height 600px;
      ul {
        height 33.3%
        display flex
        li {
          text-align center
          width 33%
          div{
            padding 0.3rem 0px
            margin 0.2rem 0px
            h3{
              font-size: 0.45rem;
              font-family: Bahnschrift;
              font-weight: 400;
              color: #444;
            }
          }
          p {
            font-size 0.3rem
            color #999
          }
        }
      .li_style{
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        }
      }
    }
.chart {
    border-bottom 30px solid #F5F6F8
    width: 100%
    .c_title {
      padding 30px 80px
      border-bottom 1px solid #EEEEEE
      .span {
        font-size:48px;
        font-family:PingFang SC;
        font-weight:bold;
        color:rgba(61,61,61,1);
      }
    }
    ul {
        width: 55%;
        overflow: hidden;
        margin: 60px auto;
        text-align center
        li {
          width: 50%;
          display: block;
          float: left;
          border 1px solid #EEEEEE
          padding-top 10px
          padding-bottom 10px
          span {
            font-size:35px;
            font-family:PingFang SC;
            font-weight:500;
            color:rgba(56,56,56,1);
          }
        }
        .select {
          border 1px solid #228ACE
          background-color #E6F2FC
        }
      }
  }
.imgstyle{
      margin 0px auto
    }
</style>
