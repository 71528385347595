/*
 * @Author: 魏鑫
 * @Date: 2020-01-09 15:18:19
 * @LastEditors  : 李波
 * @LastEditTime : 2020-01-14 10:35:12
 */
import axios from '@/lib/api.request'
// 报表数据获取
export const reptive = data =>
  axios.post('/hx/reportForm/tive', data)
